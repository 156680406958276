<template>
    <div class="page">
        <div class="head">
            <div class="btn" @click="back()">
                <back size="20px"/>
            </div>
            <div class="head_title">Terms and Conditions</div>
        </div>

        <div class="terms">
            <div class="title first_title">Introduction</div>

            <p>Welcome to Ikotun Connect, a dynamic and innovative social media application designed to foster meaningful
            connections and interactions among users in the vibrant digital landscape. These Terms and Conditions
            (“Terms”) govern your use of the Ikotun Connect App (“the App” or “the Service”) provided by Ikotun Connect,
            a registered advertising and marketing company based at Egbe Road, Ikotun, Lagos, Nigeria. By accessing and
            using the App, you expressly acknowledge and agree to be bound by these comprehensive Terms, which form a
            legally binding agreement between you and Ikotun Connect. If you do not agree with any part of these Terms,
            you may not use the App.</p>

            <p>At Ikotun Connect, we are committed to offering a secure, enjoyable, and seamless experience for all our
                users. Whether you are connecting with friends, family, or new acquaintances, exploring unique content,
                discovering exciting products, or participating in engaging community activities, we strive to create an
                environment that promotes creativity, collaboration, and authenticity.</p>

            <p><strong>Our Vision and Mission</strong></p>
            <p>Our vision is to empower individuals to connect, create, and thrive within a safe and supportive online
                community. We are on a mission to cultivate an inclusive and diverse platform that celebrates the uniqueness
                of each user, where ideas can flourish, conversations can be meaningful, and positive interactions can be
                nurtured.</p>
                
            <p><strong>Your Acceptance of These Terms</strong></p>
            <p>Your use of the App constitutes your full acceptance of these Terms, as well as our Privacy Policy and any
                other guidelines or policies referenced herein. Please review these documents carefully before using the
                App. Your continued use of the App signifies your ongoing acceptance of any changes or updates made to these
                Terms, as we may occasionally modify them to reflect evolving technology, business practices, or legal
                requirements.</p>

            <p><strong>Eligibility and User Representation</strong></p>
            <p>To access and use the App, you must be at least 18 years old and legally capable of entering into a binding
                agreement. By using the App, you represent and warrant that you meet these eligibility requirements. If you
                are accessing the App on behalf of an organization or entity, you further represent and warrant that you
                have the authority to bind that entity to these Terms, and “you” refers to both you as an individual and the
                entity.</p>

            <p><strong>Our License to You</strong></p>
            <p>Subject to your compliance with these Terms, we grant you a limited, revocable, non-exclusive,
                non-transferable, and non-sublicensable license to access and use the App for personal, non-commercial
                purposes. This license does not entitle you to use the App for any other purpose without our express written
                consent. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license,
                create derivative works from, transfer, or sell any information, software, products, or services obtained
                from the App.</p>

            <p><strong>Responsible Use and Prohibited Activities</strong></p>
            <p>As an integral member of the Ikotun Connect community, we expect you to use the App responsibly and adhere to
                the highest ethical standards. You are solely responsible for your conduct and interactions on the App. By
                using the App, you agree not to engage in any harmful, offensive, abusive, discriminatory, or harassing
                behavior. Additionally, you shall not:</p>
            <p>Post, upload, transmit, or otherwise make available any content that infringes upon any copyright, trademark,
                patent, trade secret, or other proprietary rights of any party.</p>
            <p>Impersonate any person or entity or falsely represent your affiliation with any individual, organization, or
                entity.</p>
            <p>Harass, threaten, or intimidate others or engage in any behavior that promotes violence or harm.</p>
            <p>Post, share, or transmit any content that is unlawful, defamatory, obscene, pornographic, offensive, or
                otherwise objectionable.</p>
            <p>Circumvent, disable, or interfere with any security-related features or mechanisms of the App.</p>
            <p>Use any automated system, including bots or spiders, to access, monitor, or extract data from the App without
                our express written consent.</p>
            <p>Violate any applicable local, state, national, or international laws, regulations, or treaties.</p>
            
            <p><strong>Your Obligations</strong></p>
            <p>By using the App, you acknowledge and agree that you are solely responsible for your content and interactions
                with other users. You shall:</p>
            <p>Comply with all applicable laws, regulations, and industry standards related to your use of the App.</p>
            <p>Safeguard your login credentials and refrain from sharing your account information with others.</p>
            <p>Notify us immediately if you suspect any unauthorized access to or use of your account.</p>
            <p>Provide accurate and up-to-date information during the registration process and promptly update any changes.
            </p>
            <p>Refrain from engaging in any activity that could harm or disrupt the App’s functionality, infrastructure, or
                user experience.</p>

            <p><strong>Our Intellectual Property</strong></p>
            <p>Ikotun Connect and all associated trademarks, logos, and service marks used on the App are the exclusive
                property of Ikotun Connect and its licensors. Except as expressly provided in these Terms, no license or
                right, express or implied, is granted to you for any intellectual property owned or controlled by Ikotun
                Connect or its licensors.</p>

            <p><strong>Privacy and Data Collection</strong></p>
            <p>At Ikotun Connect, we respect your privacy and are committed to safeguarding your personal information. We
                collect and use your data in accordance with our Privacy Policy, which outlines the types of data we
                collect, how we use it, and how we protect your privacy. By using the App, you consent to our data practices
                as described in the Privacy Policy.</p>

            <p><strong>Third-Party Services and Content</strong></p>
            <p>The App may contain links to third-party websites, applications, or services that are not owned or controlled
                by Ikotun Connect. We do not endorse, warrant, or assume responsibility for the content, privacy policies,
                or practices of such third-party services. You acknowledge and agree that Ikotun Connect shall not be liable
                for any damages or losses arising from your use of or reliance on any third-party services.</p>
            
            <p><strong>Disclaimer of Warranty</strong></p>
            <p>The App and its contents are provided on an “as-is” and “as-available” basis, without any warranties, either
                express or implied. Ikotun Connect disclaims all warranties, including but not limited to, warranties of
                merchantability, fitness for a particular purpose, and non-infringement.</p>

            <p><strong>Limitation of Liability</strong></p>
            <p>To the maximum extent permitted by applicable law, Ikotun Connect and its affiliates, officers, directors,
                employees, agents, partners, and licensors shall not be liable for any direct, indirect, incidental,
                special, consequential, or exemplary damages, including but not limited to, damages for loss of profits,
                goodwill, use, data, or other intangible losses arising from your use of the App or inability to use the
                App.</p>

            <p><strong>Indemnification</strong></p>
            <p>You agree to indemnify and hold harmless Ikotun Connect and its affiliates, officers, directors, employees,
                agents, partners, and licensors from and against any claims, liabilities, damages, losses, and expenses,
                including reasonable attorney fees and costs, arising from your use of the App or any violation of these
                Terms.</p>

            <p><strong>Modification of Terms</strong></p>
            <p>Ikotun Connect reserves the right, in its sole discretion, to modify, amend, update, or replace any part of
                these Terms at any time. Such changes shall be effective immediately upon posting on the App. Your continued
                use of the App following the posting of changes constitutes your acceptance of the modified Terms.</p>
            
            <p><strong>Termination</strong></p>
            <p>We may, at our discretion, suspend or terminate your access to the App at any time and for any reason, with
                or without prior notice. Upon termination, your account and access to the App will be deactivated, and
                Ikotun Connect may delete any content associated with your account.</p>
            
            <p><strong>Contact Information</strong></p>
            <p>For any questions, concerns, or inquiries about these Terms or the App, please contact us at:</p>
            <p>Ikotun Connect Address: Egbe Road, Ikotun, Lagos, Nigeria Email: <a
                    href="mailto:support@ikotunconnect.com">support@ikotunconnect.com</a></p>



            
            <div class="title">Definitions</div>
            <p>In these Terms:</p>
            <p>"App" refers to the social media application provided by Ikotun Connect.</p>
            <p>"User" refers to any individual who accesses or uses the App.</p>
            <p>"Content" includes but is not limited to posts, images, videos, messages, and any other material shared on the App.</p>
        



            <div class="title">Use of the App</div>
            <p><strong>Connecting and Interacting with Others:</strong> Ikotun Connect provides a dynamic and engaging
            platform for users to connect with friends, family, and new acquaintances. You can explore a diverse array
            of profiles, discover shared interests, and initiate connections with others. Through the App’s intuitive
            interface, you can send friend requests, accept invitations, and maintain an active social network that
            reflects your unique personality and interests.</p>
            <p><strong>Content Creation and Sharing:</strong> As a user of Ikotun Connect, you have the creative freedom to
                express yourself through various content types, including posts, images, videos, and messages. Share your
                thoughts, experiences, and insights with your network or the broader community. The App supports interactive
                features such as likes, comments, and sharing, enabling you to receive feedback and engage in meaningful
                discussions with fellow users.</p>
            <p>c. <strong>Discovering Unique Content:</strong> With a vast and diverse user base, Ikotun Connect offers an
                exciting opportunity to explore unique content from around the world. Discover captivating stories,
                beautiful artwork, informative articles, entertaining videos, and much more. The App’s sophisticated
                algorithms help curate personalized content feeds based on your interests and interactions, ensuring you
                stay connected to what matters most to you.</p>
            <p><strong>Private and Group Chat:</strong> Ikotun Connect facilitates private messaging and group chat
                functionalities, providing a secure and convenient way to communicate with other users. Connect with friends
                or join interest-based groups to engage in enriching conversations, plan events, or collaborate on shared
                projects. The App prioritizes user privacy, ensuring that your messages remain confidential and accessible
                only to the intended recipients.</p>
            <p><strong>Product Listing and Purchase:</strong> As a registered user on Ikotun Connect, you have the
                opportunity to explore a virtual marketplace where individuals and businesses can list products for sale.
                Whether you’re a buyer seeking unique items or a seller looking to expand your reach, the App provides a
                user-friendly platform for safe and seamless transactions. Sellers can showcase their products with detailed
                descriptions, images, and pricing, while buyers can browse through a vast array of items, contact sellers,
                and make purchases with confidence.</p>
            <p><strong>Airtime and Data Purchase:</strong> Stay connected with your loved ones and the world by conveniently
                purchasing airtime and data bundles through Ikotun Connect. The App partners with reputable mobile network
                providers to offer a hassle-free and secure way to top up your mobile credit or data allowance. Never miss
                an important call or message, and stay connected wherever you are.</p>
            <p><strong>Payment for Utilities and Services:</strong> Ikotun Connect strives to enhance your convenience by
                offering a convenient platform for bill payments. Easily settle your electricity bills, cable TV
                subscriptions, and other services directly through the App. By partnering with trusted service providers,
                the App streamlines the payment process, saving you time and effort.</p>
            <p><strong>Participation in Monthly Elections:</strong> Immerse yourself in the vibrant community spirit of
                Ikotun Connect by participating in monthly elections. Engage with other users in friendly competitions,
                debates, and voting activities to contribute to the selection of outstanding individuals or content within
                the community. Monthly elections serve as an exciting and inclusive platform for recognizing talents, ideas,
                and achievements, fostering a sense of camaraderie among users.</p>
            <p><strong>Ensuring Safety and Moderation:</strong> At Ikotun Connect, we prioritize the safety and well-being
                of our users. Our team of dedicated moderators actively monitors content, interactions, and activities to
                uphold community guidelines and prevent harmful or inappropriate behavior. We strive to maintain a
                respectful and supportive environment for all users, where diverse perspectives can coexist harmoniously.
            </p>
            <p><strong>Enhancing User Experience:</strong> As a user-centric platform, Ikotun Connect is committed to
                continually enhancing the user experience. We regularly update and improve the App’s features,
                functionality, and performance based on user feedback and evolving industry trends. By staying at the
                forefront of technology, we ensure that your interactions on Ikotun Connect remain seamless, enjoyable, and
                in line with your preferences.</p>
            <p><strong>Promoting Diversity and Inclusion:</strong> Ikotun Connect celebrates diversity and promotes
                inclusivity across all aspects of the App. We value the contributions of users from various backgrounds,
                cultures, and experiences, recognizing that diversity enriches the collective experience. Our commitment to
                fostering an inclusive community ensures that all users feel welcome, respected, and valued.</p>
            <p><strong>Empowering Creativity and Innovation:</strong> Through the App’s myriad features, Ikotun Connect
                empowers users to unleash their creativity and explore innovative ways to connect, collaborate, and share.
                Whether it’s expressing artistic talents, initiating social initiatives, or participating in community
                events, the App serves as a canvas for creative expression and social impact.</p>
            <p><strong>Ensuring User Data Protection:</strong> At Ikotun Connect, your privacy and data security are of
                utmost importance. We employ industry-standard security measures to protect your personal information and
                ensure that your data is handled responsibly. We do not collect any hidden data and only retain the data you
                voluntarily provide during the registration process, in compliance with applicable data protection laws and
                our Privacy Policy.</p>
            <p><strong>Collaborating with Brands and Organizations:</strong> Ikotun Connect serves as a bridge between users
                and brands or organizations seeking to engage with a vibrant and diverse audience. We offer targeted
                advertising and promotional opportunities to connect brands with interested users and enhance their
                visibility. Our commitment to transparency ensures that users have control over their ad preferences and
                experience.</p>
            <p><strong>Staying Informed and Empowered:</strong> The App serves as a valuable resource for users to stay
                informed about the latest news, trends, and developments in various fields. Users can access and share
                information on a wide range of topics, from entertainment and technology to health and lifestyle, empowering
                them to make informed decisions and broaden their knowledge.</p>
            <p><strong>Cultivating Positive Digital Citizenship:</strong> Ikotun Connect encourages positive digital
                citizenship among its users. We advocate responsible online behavior, respect for intellectual property
                rights, and adherence to community guidelines. By fostering a culture of empathy, respect, and kindness, we
                aim to create a nurturing digital ecosystem that sets a positive example for the broader digital community.
            </p>
            <p><strong>Providing Support and Assistance:</strong> At Ikotun Connect, we strive to be responsive and
                supportive to our users’ needs. If you encounter any issues, have inquiries, or require assistance, our
                dedicated support team is readily available to provide guidance and resolution. We value open communication
                and endeavor to address user concerns promptly and effectively.</p>
            <p><strong>Navigating Community Guidelines and Policies:</strong> Ikotun Connect maintains transparent community
                guidelines and policies that outline acceptable behavior, content standards, and the consequences of
                violating such guidelines. By adhering to these guidelines, users contribute to the creation of a safe,
                respectful, and enjoyable digital space for all members.</p>
            <p><strong>Participating in User Surveys and Feedback:</strong> Your feedback is invaluable to us. Ikotun
                Connect frequently conducts user surveys and seeks input from the community to better understand user
                preferences and expectations. Your participation in surveys and feedback sessions helps shape the future
                direction of the App and ensures that it aligns with user needs.</p>
            <p><strong>Building Lasting Connections and Memories:</strong> Beyond facilitating digital interactions, Ikotun
                Connect aims to forge lasting connections and memories among its users. The App’s features enable users to
                commemorate milestones, share cherished memories, and strengthen the bonds they form within the community.
            </p>
            <p><strong>Empowering Social Change and Impact:</strong> Ikotun Connect recognizes the potential of social media
                to drive positive change and impact. We support users who leverage the platform for social initiatives,
                philanthropic endeavors, and advocacy for important causes. By amplifying these efforts, the App becomes a
                catalyst for social progress and community-driven change.</p>
            <p><strong>Engaging with Influencers and Creatives:</strong> The App welcomes influencers, creatives, and
                content creators from diverse backgrounds to enrich the platform with their unique perspectives and talents.
                By fostering a supportive ecosystem for influencers, we promote their growth and success within the App,
                creating meaningful partnerships and collaborations.</p>
            <p><strong>Creating and Joining Events:</strong> Ikotun Connect facilitates event creation and attendance,
                empowering users to organize or participate in gatherings, meetups, webinars, and virtual events. These
                events serve as opportunities to network, learn, and engage with like-minded individuals, fostering a sense
                of community and shared interests.</p>
            <p><strong>Building Professional Networks:</strong> In addition to personal connections, Ikotun Connect provides
                a platform for users to build professional networks and explore career opportunities. Whether you’re seeking
                professional advice, looking for job openings, or connecting with industry experts, the App serves as a
                valuable resource for career growth and development.</p>
            <p><strong>Contributing to User-Curated Content:</strong> Ikotun Connect encourages users to contribute to
                user-curated content, such as trending topics, challenges, and creative campaigns. By actively engaging with
                these content initiatives, users become an integral part of the App’s evolving narrative and collective
                identity.</p>
            <p><strong>Experiencing Unmatched Convenience:</strong> Throughout your journey on Ikotun Connect, we strive to
                provide unmatched convenience through streamlined navigation, user-friendly interfaces, and intuitive
                features. Our commitment to simplicity and accessibility ensures that you can seamlessly navigate the App
                and maximize your overall experience.</p>
            <p>In conclusion, Ikotun Connect is a vibrant and multifaceted social media application that seeks to elevate
                your digital experience, empower your creativity, and facilitate meaningful connections with others. Through
                a range of engaging features and user-centric initiatives, the App aims to create an inclusive, secure, and
                inspiring community where users can thrive, express themselves, and make lasting impressions.</p>
        


            <div class="title">User Content</div>
            <p><strong>Your Creative Playground:</strong> At Ikotun Connect, we believe in celebrating and amplifying your
                creativity. User-generated content lies at the heart of our vibrant community, offering an unparalleled
                creative playground for individuals to express themselves authentically and share their unique perspectives
                with the world. Whether you’re an aspiring artist, a budding writer, an amateur photographer, or a seasoned
                content creator, the App welcomes all forms of creative expression, fostering an inclusive and supportive
                space for users to showcase their talents.</p>
            <p><strong>Diverse Content Types:</strong> Ikotun Connect embraces a wide array of content types, enabling users
                to curate and share a rich tapestry of experiences. From thought-provoking articles and captivating
                storytelling to visually stunning artwork and awe-inspiring photography, the App accommodates diverse
                creative expressions, catering to a diverse and engaged audience. Users can also explore trending
                challenges, participate in creative campaigns, and contribute to evolving content trends, fostering a sense
                of community and shared interests.</p>
            <p><strong>Empowering Storytelling:</strong> Storytelling lies at the core of human connection, and Ikotun
                Connect empowers users to weave compelling narratives that resonate with others. Through evocative posts,
                users can share personal anecdotes, life lessons, or insights into their passions, inspiring others and
                fostering empathy and understanding within the community. The App serves as a virtual canvas where
                individual stories merge to create a rich tapestry of human experiences, highlighting the power of
                storytelling in building lasting connections.</p>
            <p><strong>Visual Splendor:</strong> A picture is worth a thousand words, and the App harnesses the visual
                medium to convey emotions, ideas, and perspectives. Users can capture and share stunning visuals, from
                breathtaking landscapes and artistic compositions to snapshots of everyday moments that evoke emotions and
                spark conversations. With the App’s emphasis on visual splendor, users can create and appreciate art in its
                various forms, immersing themselves in a world of visual storytelling.</p>
            <p><strong>Enabling Creativity through Video:</strong> Video content has emerged as a powerful storytelling
                medium, and Ikotun Connect embraces the trend by enabling users to share engaging and immersive videos.
                Whether it’s a vlog documenting travel adventures, a tutorial showcasing artistic techniques, or a heartfelt
                message to the community, video content empowers users to connect with their audience on a deeper level,
                conveying emotions and authenticity in real-time.</p>
            <p><strong>Fostering a Safe and Inclusive Environment:</strong> While user-generated content enriches the App’s
                community, Ikotun Connect remains committed to fostering a safe and inclusive environment. Our dedicated
                team of content moderators diligently reviews and monitors user content to ensure compliance with community
                guidelines and policies. By actively curating content and taking appropriate action against harmful or
                inappropriate material, the App maintains a positive and respectful ecosystem for all users.</p>
            <p><strong>Respecting Intellectual Property:</strong> At Ikotun Connect, we uphold the principles of
                intellectual property rights and respect the creative efforts of our users. Users are encouraged to share
                original content that they own or have the necessary rights to distribute. We advocate for responsible
                content creation and discourage any form of plagiarism, infringement, or unauthorized use of copyrighted
                materials. By upholding these standards, the App promotes ethical content sharing and a culture of creative
                integrity.</p>
            <p><strong>Community Engagement and Interaction:</strong> User-generated content serves as a catalyst for
                community engagement and interaction on Ikotun Connect. As users share their creations, they invite
                feedback, reactions, and comments from the community, fostering dynamic and meaningful interactions. Content
                creators can engage in dialogue with their audience, gain valuable insights, and form genuine connections,
                creating a virtual support network that amplifies their reach and impact.</p>
            <p><strong>Exploring Niche Interests and Micro-Communities:</strong> The App’s user-generated content spans an
                array of interests and niches, enabling users to find and connect with like-minded individuals through
                micro-communities. Whether you’re passionate about niche hobbies, specific industries, or subcultures,
                Ikotun Connect facilitates the discovery of niche content and offers a sense of belonging within smaller,
                tightly-knit circles.</p>
            <p><strong>Promoting Positive Vibes and Empathy:</strong> Ikotun Connect emphasizes promoting positive vibes,
                empathy, and constructive conversations within the community. User-generated content plays a pivotal role in
                shaping the App’s culture, and we encourage users to approach content creation and engagement with kindness,
                empathy, and respect for diverse perspectives. By fostering a culture of positivity, the App creates a
                nourishing digital space where users feel encouraged to express themselves authentically and without fear of
                judgment.</p>
            <p><strong>Navigating Copyright and Content Ownership:</strong> As users share their creations on the App, it’s
                essential to understand copyright and content ownership principles. Users are encouraged to respect
                intellectual property rights and ensure that they have the necessary permissions to share content that
                includes copyrighted materials. Additionally, Ikotun Connect provides clear mechanisms for reporting
                copyright infringements, promoting a fair and ethical content-sharing environment.</p>
            <p><strong>Quality and Authenticity:</strong> Ikotun Connect values quality and authenticity in user-generated
                content. The App promotes content that reflects originality, creativity, and meaningful contributions to the
                community. To maintain a standard of excellence, users are encouraged to produce high-quality content that
                resonates with the community and adds value to the collective experience.</p>
            <p><strong>Navigating Sensitive Topics and Controversial Content:</strong> While the App celebrates diverse
                perspectives and creative expressions, it is crucial to approach sensitive topics and controversial content
                with care and consideration. Users are encouraged to be mindful of potential impacts on others and to adhere
                to community guidelines when discussing sensitive issues. The App fosters a culture of constructive
                dialogue, enabling users to express differing viewpoints while maintaining respect and empathy.</p>
            <p><strong>Encouraging Collaborative Content Creation:</strong> Collaboration fuels innovation, and Ikotun
                Connect actively encourages users to engage in collaborative content creation. Whether it’s co-authoring
                articles, conducting joint projects, or collaborating on creative campaigns, working together cultivates a
                sense of camaraderie and shared achievement within the community.</p>
            <p><strong>Respecting Privacy and Consent:</strong> Ikotun Connect advocates for user privacy and respect for
                personal boundaries. Users are reminded to obtain appropriate consent before sharing content that involves
                other individuals, ensuring that privacy rights are upheld and respected.</p>
            <p><strong>Personal Growth and Reflection:</strong> User-generated content on Ikotun Connect extends beyond mere
                self-expression; it serves as a medium for personal growth and reflection. Through content creation, users
                can articulate their thoughts, emotions, and experiences, fostering self-awareness and personal development.
                The App becomes a space for users to navigate their journeys, find inspiration, and receive support from a
                diverse community.</p>
            <p><strong>Content Recommendations and Discovery:</strong> The App’s sophisticated algorithms work tirelessly to
                personalize content recommendations for each user, ensuring that you discover content that aligns with your
                interests and preferences. By analyzing user interactions, engagement patterns, and content consumption, the
                App presents a curated feed that resonates with your unique tastes, enhancing your content discovery
                experience.</p>
            <p><strong>Content Metrics and Insights:</strong> For content creators, Ikotun Connect provides valuable metrics
                and insights to measure content performance and audience engagement. These metrics offer visibility into the
                reach, interactions, and impact of user-generated content, empowering creators to refine their strategies
                and enhance the effectiveness of their storytelling.</p>
            <p><strong>Navigating Virality and Trends:</strong> The App’s dynamic and ever-evolving nature invites users to
                navigate content virality and trends. Engaging with trending challenges, topics, and viral content allows
                users to contribute to content trends and become part of the App’s dynamic cultural landscape.</p>
            <p><strong>Empowering Brand Storytelling:</strong> Beyond individual users, Ikotun Connect offers an opportunity
                for brands and organizations to leverage user-generated content for brand storytelling and engagement. By
                collaborating with content creators and influencers, brands can create authentic and relatable narratives
                that resonate with their target audience, fostering brand loyalty and advocacy.</p>
            <p><strong>Content Moderation and Feedback:</strong> Ikotun Connect’s commitment to content moderation ensures
                that the App remains a safe, respectful, and enriching digital environment. Users are encouraged to provide
                feedback on content and report any concerns they may encounter. The App values user input, and feedback
                plays a vital role in shaping content guidelines and community standards.</p>
            <p><strong>User-Generated Content as a Catalyst for Change:</strong> User-generated content has the power to
                spark conversations, challenge norms, and drive positive change. Ikotun Connect encourages users to use
                their creative voices to advocate for causes they believe in, raising awareness and effecting positive
                social impact.</p>
            <p><strong>Content Diversity and Inclusivity:</strong> The App values diversity and inclusivity in all forms of
                content. Users are encouraged to embrace content that reflects the broad spectrum of human experiences,
                cultures, and perspectives. By promoting content diversity and inclusivity, the App enriches the collective
                narrative and strengthens connections among users with varied backgrounds and identities.</p>
            <p><strong>Building an Online Legacy:</strong> Ikotun Connect offers a digital canvas for users to build an
                online legacy. As users create content, they leave behind a digital footprint that captures their journey,
                experiences, and contributions to the community. This digital legacy becomes a testament to their creative
                spirit and lasting impact within the App’s dynamic ecosystem.</p>
            <p><strong>Responsible Content Sharing and Engagement:</strong> With great creative freedom comes great
                responsibility. Ikotun Connect encourages responsible content sharing and engagement, fostering an
                environment where users are mindful of the potential effects of their content on others. Responsible content
                sharing entails upholding community guidelines, adhering to content policies, and being considerate of
                diverse audience perspectives.</p>
            <p><strong>Content as an Expression of Identity:</strong> User-generated content serves as an avenue for users
                to express their unique identities and explore their passions. Whether it’s sharing cultural traditions,
                advocating for social causes, or delving into niche interests, content creation becomes a canvas for users
                to celebrate their individuality and connect with others who share similar values.</p>
            <p>In essence, user-generated content forms the vibrant tapestry of Ikotun Connect, weaving together diverse
                stories, experiences, and perspectives. By empowering users to unleash their creativity, share their
                authentic voices, and engage with a supportive community, the App becomes a testament to the boundless
                potential of human expression and the transformative power of digital connection</p>
        



            <div class="title">Services and Features</div>
            <p>Ikotun Connect takes pride in offering a wide array of services and features designed to provide an engaging
                and seamless social media experience for its users. Our platform strives to cater to the diverse needs and
                preferences of our community, ensuring that every individual finds a space to connect, share, and explore in
                a meaningful way. In this comprehensive overview, we present an in-depth look at the various Services and
                Features that make Ikotun Connect a one-of-a-kind social media application.</p>
            <p><strong>Private Chat:</strong> At Ikotun Connect, we understand the importance of privacy and personal
                connections. Our Private Chat feature allows users to engage in one-on-one conversations, fostering intimate
                and confidential interactions. Whether you want to catch up with an old friend, discuss a shared interest,
                or exchange heartfelt messages, the Private Chat feature offers a secure environment to connect with others
                on a more personal level.</p>
            <p><strong>Group Chat:</strong> For those who prefer vibrant group discussions and lively conversations, our
                Group Chat feature is a perfect fit. Create or join interest-based groups, collaborate with like-minded
                individuals, and stay informed on topics that matter to you. From hobbyists to professionals, our Group Chat
                feature encourages active participation and strengthens the sense of community within Ikotun Connect.</p>
            <p><strong>Content Creation:</strong> Express yourself freely through Content Creation on Ikotun Connect. Share
                your thoughts, experiences, creativity, and passions with the world. Whether it’s posting captivating
                images, engaging videos, thought-provoking articles, or inspiring poetry, the platform welcomes diverse
                forms of expression and encourages users to find their voice in a supportive and appreciative environment.
            </p>
            <p><strong>Reactions:</strong> Sometimes, words are not enough to convey emotions. That’s where our Reactions
                feature comes in handy. Respond to posts and comments with a wide range of emotive reactions, adding nuance
                and depth to interactions. From ‘Like’ to ‘Love,’ ‘Haha’ to ‘Wow,’ ‘Sad’ to ‘Angry,’ our Reaction feature
                enables users to communicate their feelings more accurately and empathetically.</p>
            <p><strong>Product Listing:</strong> Inclusivity and community support are vital to Ikotun Connect. Our Product
                Listing feature empowers users to showcase products they create or wish to sell within the Ikotun Connect
                community. Whether you’re an artist, entrepreneur, or simply have unique items to share, this feature allows
                you to reach a broader audience and explore potential collaborations.</p>
            <p><strong>Experts (Professional Showcase):</strong> We celebrate expertise and knowledge at Ikotun Connect. The
                Experts feature enables professionals to create a showcase of their skills, experience, and services. From
                career coaches to musicians, writers to photographers, our platform is a hub for talent and professional
                networking, connecting experts with enthusiasts and potential clients.</p>
            <p><strong>Product Purchase:</strong> Simplifying online shopping is one of our core objectives. With our
                Product Purchase feature, users can buy products directly from the App, supporting sellers within the Ikotun
                Connect community. Shopping on the platform ensures a seamless transaction experience and strengthens the
                bond between creators and consumers.</p>
            <p><strong>Airtime Purchase:</strong> Staying connected is crucial in today’s fast-paced world. Our Airtime
                Purchase feature allows users to top up their mobile phones with airtime directly from the App. No more
                hassle of navigating external websites; Ikotun Connect streamlines the process, saving time and effort.</p>
            <p><strong>Data Purchase:</strong> In an increasingly digital era, having access to data is essential. Our Data
                Purchase feature enables users to buy data plans for their mobile devices conveniently. Stay connected,
                informed, and engaged with Ikotun Connect’s user-friendly data purchase options.</p>
            <p><strong>Electricity Bill Payment:</strong> Ikotun Connect aims to be a holistic platform, addressing
                practical needs as well. Our Electricity Bill Payment feature enables users to pay their electricity bills
                securely and conveniently, without the need to leave the App. Managing utilities becomes hassle-free within
                Ikotun Connect.</p>
            <p><strong>Cable TV Payment:</strong> Entertain yourself without interruption by utilizing our Cable TV Payment
                feature. Seamlessly recharge your cable TV subscriptions directly from the App, ensuring uninterrupted
                access to your favorite shows and channels.</p>
            <p><strong>Participation in Monthly Elections:</strong> We cherish the democratic spirit within our community.
                Ikotun Connect hosts monthly elections where users can participate and cast their votes for various
                community-based initiatives and events. This feature empowers users to shape the direction of the community
                and make their voices heard.</p>
            <p>In conclusion, Ikotun Connect prides itself on offering a versatile and feature-rich social media
                application. With services ranging from intimate private chats to community-wide monthly elections, the
                platform seeks to foster meaningful connections, support creators and professionals, and simplify daily
                tasks. We strive to create a vibrant, inclusive, and engaging online space where users can freely express
                themselves, connect with others, and explore an ever-growing range of services. As we continue to evolve,
                our commitment to enhancing user experiences and providing innovative solutions remains unwavering. Join
                Ikotun Connect today and embark on a journey of discovery, connection, and self-expression like never
                before.</p>
                
            


            <div class="title">Embrace the Adventure: Our "Connection Disclaimer"</div>
            <p>Hey there, lovely Ikotun Connect explorers! Before we dive into the magical world of connections and
                community on our vibrant platform, we want to have an open and heartfelt chat with you. So, let’s get cozy
                and talk about our “Embrace the Adventure: Connection Disclaimer.”</p>
            <p><strong>Friendship Galore, But Proceed with Caution</strong></p>
            <p>We absolutely adore how our app brings people together, kindred spirits united by shared passions and
                interests. However, it’s essential to remember that while we provide the space for beautiful connections,
                the actual interactions are entirely up to you. We’re like your friendly neighborhood cupids, but we can’t
                be held responsible for the conversations, meetups, or events that unfold between you and your newfound
                friends.</p>
            <p><strong>Exploring the Marketplace Mindfully</strong></p>
            <p>The creative wonders and entrepreneurial spirits thriving on our marketplace are truly awe-inspiring. As you
                peruse through the offerings, let your adventurous spirit take flight, but also exercise that wonderful
                trait called caution. While we aim to create a safe space, it’s always best to double-check the legitimacy
                of products or services before diving into any transactions.</p>
            <p><strong>Respect and Privacy First</strong></p>
            <p>We’re all about fostering a culture of respect and consideration here at Ikotun Connect. Remember that each
                user is a unique individual, deserving of kindness and privacy. So, when you’re sharing your fantastic
                content or having a delightful conversation, be mindful of each other’s boundaries. Let’s keep the respect
                flowing freely and ensure that sharing is all about joy and consent.</p>
            <p><strong>Authenticity is Key</strong></p>
            <p>In this digital wonderland, authenticity is like a sparkling gem, and we encourage you to seek it out. While
                we’re diligently working to create a safe space, it’s essential to use your inner detective skills. If
                something feels off or raises an eyebrow, trust your instincts, and let us know if you spot anything fishy.
                Together, we can maintain an authentic and genuine environment for everyone.</p>
            <p>So, there you have it, our friendly “Embrace the Adventure: Connection Disclaimer.” At Ikotun Connect, we’re
                all about creating a space for you to explore, connect, and discover the magic of friendships. We’re here to
                make your journey joyful and delightful, but like any adventure, it’s essential to be cautious and mindful.
                With your beautiful spirit and our commitment to fostering a warm and caring community, we’ll create
                something truly extraordinary together. So, let’s embrace the adventure, hand in hand, and create memories
                that’ll last a lifetime!</p>

                

            <div class="title">Suspension and Termination Policy</div>
            <p>We express the “Suspension and Termination Policy” of Ikotun Connect with precision and adherence to legal
                principles. This policy serves as a vital instrument in safeguarding the integrity and reputation of the
                platform while ensuring the safety and well-being of its users. Let us delve into the legal framework of
                this policy:</p>
            <p><strong>Purpose and Scope:</strong> The “Suspension and Termination Policy” outlines the circumstances under
                which an individual’s account or access to Ikotun Connect may be suspended or terminated. It is designed to
                promote responsible conduct, foster a culture of respect, and comply with applicable laws and regulations.
            </p>
            <p><strong>User Obligations:</strong> Users of Ikotun Connect are bound by the Terms and Conditions, which
                encompass a range of obligations, including refraining from engaging in illegal activities, harassment,
                offensive behavior, or actions that may compromise the rights and privacy of others. Failure to abide by
                these obligations may result in account termination.</p>
            <p><strong>Evaluation and Decision-Making:</strong> The process of evaluating potential violations of the policy
                is undertaken diligently and fairly. Each case is carefully assessed on its own merits, taking into
                consideration relevant evidence and factors. Decisions regarding termination are made with due regard to
                applicable laws and principles of natural justice.</p>
            <p><strong>Privacy and Data Protection:</strong> In administering the Termination Policy, Ikotun Connect adheres
                to applicable data protection laws and respects user privacy. Information gathered during the evaluation
                process is handled confidentially and used solely for the purpose of addressing alleged violations and
                determining appropriate action.</p>
            <p><strong>Compliance with Laws:</strong> Ikotun Connect is committed to maintaining a platform that complies
                with all relevant laws and regulations. Users are expected to conduct themselves in accordance with the law,
                and any activities that contravene applicable legal statutes may lead to termination.</p>
            <p><strong>Suspension and Appeals:</strong> In certain instances, pending investigation or evaluation, Ikotun
                Connect may suspend a user’s account temporarily. During this period, the user may be provided an
                opportunity to present their case and appeal the suspension or termination decision. Appeals will be
                considered in accordance with the platform’s appeal process.</p>
            <p><strong>Education and Rehabilitation:</strong> In addition to termination, Ikotun Connect acknowledges the
                potential for education and rehabilitation. Under appropriate circumstances, users may be offered guidance
                and support to rectify their conduct and contribute positively to the community.</p>
            <p><strong>Legal Implications:</strong> Termination decisions carry significant consequences for users,
                impacting their access to the platform and community. As such, users are encouraged to familiarize
                themselves with the Terms and Conditions, exercise prudence in their interactions, and seek legal advice if
                required.</p>
            <p>In conclusion, the “Suspension and Termination Policy” of Ikotun Connect operates as a critical legal
                instrument to maintain the platform’s integrity and uphold community standards. It serves as a reminder of
                the responsibilities and obligations borne by users and underscores the platform’s commitment to fostering a
                secure and respectful digital environment. Compliance with this policy is essential for preserving the ethos
                of Ikotun Connect and ensuring an enjoyable and legally compliant experience for all users.</p>
        

        
            <div class="title">Limitation of Liability</div>
            <p>Hey there, wonderful Ikotun Connect users! We want to make sure you have all the necessary info about our
                “Limitation of Liability” clause, presented in a straightforward and friendly manner. So, let’s dive right
                into it!</p>
            <p><strong>What is it about?</strong></p>
            <p>The “Limitation of Liability” clause is a part of our Terms and Conditions that outlines the extent to which
                Ikotun Connect can be held responsible for any damages, losses, or injuries that may occur while using our
                App or during interactions between users.</p>
            <p><strong>Our Commitment:</strong></p>
            <p>First and foremost, we care about your safety and experience within our community. We take precautions and
                work hard to create a secure platform. However, we want to be honest with you – online interactions carry
                some inherent risks, just like in any other digital space.</p>
            <p><strong>Unforeseen Circumstances:</strong></p>
            <p>We’re all about transparency! Sometimes, unforeseen events may happen beyond our control. While we do our
                best to ensure a smooth and enjoyable experience, we want to make you aware that there might be situations
                we can’t foresee or prevent.</p>
            <p><strong>User Responsibility:</strong></p>
            <p>You play a vital role in maintaining a safe and positive community. We encourage you to exercise caution, be
                mindful of your interactions, and report any concerns you might have. Remember, we’re in this together!</p>
            <p><strong>What it Covers:</strong></p>
            <p>The Limitation of Liability clause covers various scenarios, such as technical issues, data security
                breaches, or interactions between users. It’s a legal way to make sure we don’t face excessive legal claims
                that go beyond reasonable expectations.</p>
            <p><strong>Our Pledge:</strong></p>
            <p>Even with this clause, please know that we take your safety and security very seriously. We always aim to
                meet the highest standards of care and diligence while operating Ikotun Connect.</p>
            <p><strong>Legal Compliance:</strong></p>
            <p>Rest assured, the Limitation of Liability clause aligns with the relevant laws and regulations that apply to
                platforms like ours. We’re committed to ensuring fairness and legal compliance in every aspect.</p>
            <p><strong>Working Together:</strong></p>
            <p>We believe in building a strong community that looks out for each other. By understanding and appreciating
                this clause, you empower yourself and others to have a safe and enjoyable experience within Ikotun Connect.
            </p>
            <p>In conclusion, our “Limitation of Liability” clause is all about being honest and transparent with you, our
                fantastic users. While we do everything we can to create a secure and positive platform, we also want you to
                be aware of the potential risks that come with online interactions. Together, let’s foster a caring and
                supportive community, where we look out for one another and make the most of our Ikotun Connect journey!</p>
        


            <div class="title">Amendments</div>
            <p>As a dynamic and forward-thinking platform, Ikotun Connect is committed to continuous improvement and
                innovation. Our journey together is marked by the pursuit of excellence, and in that spirit, we introduce
                the concept of “Amendments to the Terms and Conditions.” We recognize that as our community evolves and the
                digital landscape transforms, it is essential to maintain a living and adaptable set of guidelines. This
                comprehensive explanation will shed light on the significance, process, and impact of amendments to our
                Terms and Conditions.</p>
            <p><strong>Embracing the Ever-Changing Landscape:</strong></p>
            <p>The world of social media and online platforms is constantly evolving. New technologies emerge, user needs
                evolve, and regulatory frameworks change. In this ever-changing landscape, it is imperative for Ikotun
                Connect to stay ahead, ensuring that our Terms and Conditions remain relevant, up-to-date, and aligned with
                the current industry standards.</p>
            <p><strong>Why Amendments Matter:</strong></p>
            <p>Amendments to the Terms and Conditions allow us to respond proactively to emerging challenges, incorporate
                user feedback, and introduce enhancements that elevate your experience. By making necessary updates, we
                strengthen our commitment to transparency, user protection, and the promotion of a thriving and engaging
                community.</p>
            <p><strong>User Inclusivity:</strong></p>
            <p>Your voice matters! At Ikotun Connect, we prioritize user inclusivity and input. Amendments to the Terms and
                Conditions are not arbitrary decisions; rather, they reflect a collective effort to create a more inclusive
                and user-centric platform. We actively seek your feedback, concerns, and suggestions, and amendments often
                emerge from this fruitful dialogue.</p>
            <p><strong>Ensuring Transparency:</strong></p>
            <p>Amendments are not concealed documents written in obscure legal jargon. On the contrary, we maintain a
                transparent and accessible process. Any significant changes to the Terms and Conditions will be clearly
                communicated to all users. We encourage you to review these updates and ask questions if needed.</p>
            <p><strong>Material vs. Non-Material Amendments:</strong></p>
            <p>Ikotun Connect classifies amendments into two categories: material and non-material. Material amendments
                involve substantial changes that may affect your rights, obligations, or the essence of the platform.
                Non-material amendments are minor modifications that do not significantly impact the user experience.
                Material amendments undergo more extensive notification and approval processes.</p>
            <p><strong>User Notification:</strong></p>
            <p>For material amendments, we provide prominent and prior notification. This is usually done through direct
                communication channels, email notifications, or within the platform itself. The notification period allows
                you to review the changes and make an informed decision about your continued use of Ikotun Connect.</p>
            <p><strong>Continued Use as Acceptance:</strong></p>
            <p>By continuing to use Ikotun Connect after being notified of material amendments, you are deemed to have
                accepted and agreed to these updates. We value your feedback, and if you have reservations about the
                changes, you are encouraged to reach out to us so we can address your concerns.</p>
            <p><strong>Regulatory Compliance:</strong></p>
            <p>In an ever-evolving regulatory landscape, compliance with laws and regulations is essential. Amendments may
                be introduced to align our platform with changing legal requirements, ensuring a secure and legally
                compliant environment for all users.</p>
            <p><strong>Our Pledge:</strong></p>
            <p>Rest assured that we approach amendments with prudence and sensitivity. Our objective is to improve your
                experience, maintain trust, and uphold the values that define Ikotun Connect. We pledge to be diligent in
                our communication, responsive to your needs, and committed to fostering an empowered and supportive
                community.</p>
            <p>In conclusion, amendments to the Terms and Conditions represent our commitment to continuous improvement and
                responsiveness to user needs. As a community-driven platform, your input and collaboration are invaluable in
                shaping the future of Ikotun Connect. By embracing transparency, inclusivity, and user-centricity, we strive
                to create an online space that reflects your aspirations and elevates your digital interactions. We thank
                you for being an integral part of our Ikotun Connect journey, and we look forward to continuing this
                adventure together!</p>

        </div>
        
    </div>
</template>

<script>
import back from '../icons/back.vue';

export default {
    components: {
        back
    },
    methods: {
        back() {
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>
    .page {
        position: relative;
        background-color: white;
    }
    
    .head {
        display: flex;
        gap: 20px;
        align-items: center;
        padding: 0px 0px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
    }
    .btn {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color .3s;
        /* cursor: pointer; */
    }
    .btn:active {
        background-color: var(--trans);
    }
    .head_title {
        font-weight: 700;
    }


    .terms {
        height: calc(100vh - 51px);
        box-sizing: border-box;
        overflow: auto;

        max-width: 800px;
        padding: 20px 20px;
        margin: 0 auto;
    }
    .title {
        font-size: 18px;
        font-weight: 700;
        margin-top: 50px;
    }
    .first_title {
        margin-top: 0px;
    }
    p {
        font-size: 14px;
        line-height: 18px;
        text-align: justify;
    }
</style>